import React from 'react';
import styled from 'styled-components';

interface ValueEditorProps {
  value: string;
  onSave: (newValue: string) => void;
  validate?: (newValue: string) => boolean;
  normalize?: (newValue: string) => string | null;
}

const ValueEditor: React.FC<ValueEditorProps> = ({
  value,
  onSave,
  validate,
  normalize,
}) => {
  const [isEditing, setEditing] = React.useState(false);
  const [editableValue, setEditableValue] = React.useState(value);

  const handleEdit = React.useCallback(() => {
    setEditableValue(value);
    setEditing(true);
  }, [value]);

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditableValue(event.currentTarget.value);
    },
    [],
  );

  const isValid = React.useMemo(() => {
    if (!validate) {
      return true;
    }

    return validate(editableValue);
  }, [validate, editableValue]);

  const handleSave = React.useCallback(() => {
    const finalValue = normalize ? normalize(editableValue) : editableValue;

    if (finalValue) {
      onSave(finalValue);
      setEditableValue(finalValue);
      setEditing(false);
    }
  }, [normalize, editableValue, onSave]);

  const handleCancel = React.useCallback(() => {
    setEditableValue(value);
    setEditing(false);
  }, [value]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSave();
      }
    },
    [handleSave],
  );

  return (
    <Root>
      {isEditing ? (
        <>
          <Input
            autoFocus
            value={editableValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isValid}>
            Save
          </Button>
        </>
      ) : (
        <>
          <Value>{editableValue}</Value>
          <Button onClick={handleEdit}>Edit</Button>
        </>
      )}
    </Root>
  );
};

export default ValueEditor;

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
`;

const Value = styled.span`
  flex-grow: 1;
`;

const Button = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`;
