import './App.css';

import zallSdk from '@zall/sdk';
import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Analytics from 'react-router-ga';

import Layout from './components/Layout';
import OutlookLoginPage from './components/OutlookLoginPage';
import PaymentPage from './components/PaymentPage';
import ProfilePage from './components/ProfilePage';
import SigninPage from './components/SigninPage';
import SignupPage from './components/SignupPage';

zallSdk.baseUrl = process.env.REACT_APP_API_URL ?? '';

const App: React.FC = () => {
  const isNative = process.env.REACT_APP_MODE === 'native';

  return (
    <HashRouter>
      <Analytics id={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}>
        <Switch>
          {isNative && <Route path="/login" component={SigninPage} />}
          {isNative && <Route path="/signup" component={SignupPage} />}
          <Route path="/outlook/login" component={OutlookLoginPage} />
          <Route>
            <Layout>
              <Route path="/" exact component={ProfilePage} />
              {/* <Route path="/accounts" component={AccountsPage} /> */}
              <Route path="/profile" component={ProfilePage} />
              <Route path="/payment" component={PaymentPage} />
            </Layout>
          </Route>
        </Switch>
      </Analytics>
    </HashRouter>
  );
};

export default App;
