import React from 'react';
import styled from 'styled-components';

const Shortcut: React.FC<{shortcut: string}> = ({shortcut}) => {
  const keys = React.useMemo(() => {
    if (shortcut.includes('+')) {
      return shortcut.split('+');
    }

    return shortcut.split('');
  }, [shortcut]);

  return (
    <Root>
      {keys.map((key, index) => (
        <>
          {index !== 0 && <Plus>+</Plus>}
          <Key key={key}>
            <span>{getKeyLabel(key)}</span>
          </Key>
        </>
      ))}
    </Root>
  );
};

export default Shortcut;

const Root = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Plus = styled.span`
  color: #ff3eb2;
  font-size: 16.6957px;
  line-height: 20px;
  margin: 0 4px;
`;

const Key = styled.span`
  color: #ff3eb2;
  font-size: 16.6957px;
  line-height: 100%;
  border: 0.521739px solid #cad0d9;
  box-sizing: border-box;
  border-radius: 2.08696px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  padding: 0 6px;
`;

function getKeyLabel(key: string) {
  switch (key.toLowerCase()) {
    case 'command':
      return '⌘';
    case 'ctrl':
      return 'Ctrl';
    case 'alt':
      return 'Alt';
    default:
      return key.toUpperCase();
  }
}
