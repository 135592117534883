import {debounce, isEqual} from 'lodash';
import React from 'react';

import useFirstValue from './useFirstValue';
import useMutationObserver from './useMutationObserver';

export default function useElementState<T>(
  element: HTMLElement | null,
  defaultState: T,
  options: MutationObserverInit,
  parseFunc: (element: HTMLElement) => T,
): T {
  const [value, setValue] = React.useState<T>(defaultState);
  const parse = useFirstValue(parseFunc);

  React.useEffect(() => {
    element && setValue(parse(element));
  }, [element, parse]);

  const updateValue = React.useMemo(
    () =>
      debounce((newValue: T) => {
        setValue(newValue);
      }, 100),
    [],
  );

  useMutationObserver(element, options, () => {
    if (element) {
      const newValue = parse(element);

      if (!isEqual(value, newValue)) {
        updateValue(newValue);
      }
    }
  });

  return value;
}
