import React from 'react';
import useSWR from 'swr';

import useElementState from './useElementState';

const DETECT_EXTENSION_TIMEOUT = 1000;

export default function useZallExtensionVersion(): string | undefined | null {
  const version = useElementState(
    document.body,
    undefined,
    {attributes: true},
    body => body.dataset.zallExtension ?? null,
  );

  const versionRef = React.useRef<string | undefined | null>(null);

  versionRef.current = version;

  const fetcher = React.useCallback(() => {
    return new Promise<string | null>(resolve => {
      const timeout = setTimeout(() => resolve(null), DETECT_EXTENSION_TIMEOUT);

      const interval = setInterval(() => {
        if (versionRef.current) {
          clearTimeout(timeout);
          clearInterval(interval);

          resolve(versionRef.current);
        }
      }, 100);
    });
  }, []);

  const {data} = useSWR<string | null>('/extension/version', fetcher);

  return data;
}
