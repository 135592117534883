import {PlanData} from '@zall/consts';
import React from 'react';
import styled from 'styled-components';

import checkImg from '../../icons/check.svg';
import ToggleSwitch from '../common/ToggleSwitch';

interface PlanProps {
  plan: PlanData;
  image: string;
  active: boolean;
  onSwitch: (annually: boolean) => void;
  loading: boolean;
}

const Plan: React.FC<PlanProps> = ({plan, image, active, onSwitch}) => {
  const [annually, setAnnually] = React.useState(true);

  const savePerc = React.useMemo(() => {
    if (!plan.price) {
      return 0;
    }

    return (
      ((plan.price.monthly - plan.price.annually / 12) / plan.price.monthly) *
      100
    ).toFixed();
  }, [plan.price]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAnnually(event.currentTarget.checked);
    },
    [],
  );

  const handleSwitch = React.useCallback(() => {
    onSwitch(annually);
  }, [onSwitch, annually]);

  return (
    <Root active={active}>
      <Header>
        <img src={image} alt={plan.name} />
        <h2>{plan.name}</h2>
      </Header>
      <PriceLine1>
        {plan.price ? (
          <span>
            $<b>{annually ? plan.price.annually : plan.price.monthly}</b>
            <span className="pad">/{annually ? 'year' : 'month'}</span>
          </span>
        ) : (
          <span>
            <b>Free</b> forever
          </span>
        )}
      </PriceLine1>
      <PriceToggle>
        {plan.price && (
          <>
            <PriceToggleLabel active={!annually}>monthly</PriceToggleLabel>
            <PriceToggleCont>
              <ToggleSwitch checked={annually} onChange={handleChange} />
            </PriceToggleCont>
            <PriceToggleLabel active={annually}>annually</PriceToggleLabel>
          </>
        )}
      </PriceToggle>
      <PriceLine2>
        {plan.price && annually ? (
          <span>
            Save {savePerc}% monthly is ${plan.price.monthly} / mo
          </span>
        ) : null}
      </PriceLine2>
      <List>
        {plan.features.map(feature => (
          <ListItem key={feature}>{feature}</ListItem>
        ))}
      </List>
      <Button onClick={active ? undefined : handleSwitch} disabled={active}>
        {active ? 'Current Plan' : plan.price ? 'Upgrade' : 'Switch'}
      </Button>
    </Root>
  );
};

export default Plan;

const Root = styled.div<{active?: boolean}>`
  width: 326px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  border: 1px solid #a4a8b7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({active}) =>
    active &&
    `
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        height: 9px;
        background: #ff3eb2;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 24px;
    line-height: 130%;
    margin-left: 14px;
  }
`;

const PriceToggle = styled.div`
  margin-top: 7px;
  display: flex;
  height: 25px;
`;

const PriceToggleLabel = styled.div<{active: boolean}>`
  color: #a4a8b7;

  ${({active}) =>
    active &&
    `
      color: #081023;
      font-weight: 500;
  `}
`;

const PriceToggleCont = styled.div`
  margin: 0 13px;
`;

const List = styled.ul`
  flex-grow: 1;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 12px 0;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 151%;
  color: #000000;
  white-space: nowrap;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 14px;
    background-image: url(${checkImg});
    background-repeat: no-repeat;
    margin-top: 4.5px;
    margin-right: 18px;
  }
`;

const PriceLine1 = styled.div`
  font-size: 24px;
  line-height: 120%;
  text-align: right;
  color: #000000;
  margin-top: 18px;

  .pad {
    margin-left: 2px;
  }
`;

const PriceLine2 = styled.div`
  font-size: 12px;
  line-height: 120%;
  margin-top: 2px;
  color: #ff3eb2;
  width: 100%;
  height: 22px;
  text-align: center;

  span {
    display: inline-block;
    padding: 4px;
    width: 100%;
    background: rgba(255, 62, 178, 0.1);
    border-radius: 2px;
  }
`;

const Button = styled.button`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 180%;
  color: #ff3eb2;
  background: none;
  border: 0.5px solid #ff3eb2;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 6px 12px;
  cursor: pointer;

  &[disabled] {
    border: 0.5px solid #a4a8b7;
    color: #081023;
    cursor: default;
  }
`;
