import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

export interface LinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

const Link: React.FC<LinkProps> = ({href, className, children}) => {
  return (
    <RouterLink to={href} className={className}>
      {children}
    </RouterLink>
  );
};

export default Link;
