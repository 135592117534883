import dayjs from 'dayjs';
import {DateTime} from 'luxon';
import * as rrule from 'rrule';

import {NylasEvent, NylasTimespan, NylasWhen} from '../types/nylas';
import hasOwnProperty from './hasOwnProperty';

interface EventTime {
  start: Date;
  end?: Date;
  allDay?: boolean;
}

export function parseDate(when: Omit<NylasWhen, 'object'>): EventTime {
  if (hasOwnProperty(when, 'date') && typeof when.date === 'string') {
    return {
      start: new Date(when.date),
      allDay: true,
    };
  }

  if (hasOwnProperty(when, 'time') && typeof when.time === 'number') {
    return {
      start: new Date(when.time * 1000),
      allDay: false,
    };
  }

  if (
    hasOwnProperty(when, 'start_date') &&
    typeof when.start_date === 'string' &&
    hasOwnProperty(when, 'end_date') &&
    typeof when.end_date === 'string'
  ) {
    return {
      start: new Date(when.start_date),
      end: new Date(new Date(when.end_date).getTime() + 86400000),
      allDay: true,
    };
  }

  if (
    hasOwnProperty(when, 'start_time') &&
    typeof when.start_time === 'number' &&
    hasOwnProperty(when, 'end_time') &&
    typeof when.end_time === 'number'
  ) {
    const timezones = when as unknown as NylasTimespan;

    return {
      start: DateTime.fromSeconds(when.start_time, {
        zone: timezones.start_timezone ?? 'UTC',
      }).toJSDate(),
      end: DateTime.fromSeconds(when.end_time, {
        zone: timezones.end_timezone ?? 'UTC',
      }).toJSDate(),
      allDay: false,
    };
  }

  throw new Error('Invalid when');
}

export function getDuration({
  start,
  end,
}: EventTime): {milliseconds: number} | undefined {
  if (!end) {
    return undefined;
  }

  return {milliseconds: end.getTime() - start.getTime()};
}

export function parseRRule(
  event: NylasEvent,
  {start}: EventTime,
): string | undefined {
  if (!event.recurrence?.rrule[0]) {
    return undefined;
  }

  const dtstart = convertTimezone(start, event.recurrence?.timezone);

  const resultingRRule = [
    new rrule.RRule({
      dtstart,
      tzid: event.recurrence?.timezone ?? null,
    }).toString(),
  ]
    .concat(event.recurrence.rrule)
    .join('\n');

  rrule.rrulestr(resultingRRule);

  return resultingRRule;
}

export function parseExdate(
  event: NylasEvent,
  events: NylasEvent[],
): string[] | undefined {
  const dates = events
    .filter(
      event2 =>
        event2.master_event_id === event.id && event2.original_start_time,
    )
    .map(event2 =>
      convertTimezone(
        new Date(event2.original_start_time * 1000),
        event.recurrence?.timezone,
      ).toISOString(),
    );

  if (!dates.length) {
    return undefined;
  }

  return dates;
}

export function isRRuleInDateRange(
  event: NylasEvent,
  start: Date,
  end: Date,
  exDates?: string[],
): boolean {
  try {
    const date = parseDate(event.when);
    const parsedRrule = parseRRule(event, date);
    if (parsedRrule) {
      const rule = rrule.RRule.fromString(parsedRrule);
      const exDatesArray =
        exDates?.map(date => dayjs(date).format('YY-MM-DD')) ?? [];
      const dates = rule
        .between(start, end)
        .filter(date => !exDatesArray.includes(dayjs(date).format('YY-MM-DD')));
      return dates.length > 0;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}

export function convertTimezone(date: Date, timezone?: string): Date {
  return new Date(
    `${DateTime.fromJSDate(date)
      .setZone(timezone)
      .toISO({includeOffset: false})}Z`,
  );
}

export function unixTimestampToDateString(
  timestamp?: number,
  format?: string,
): string {
  return DateTime.fromMillis((timestamp ?? 0) * 1000).toFormat(format ?? 'DD');
}
