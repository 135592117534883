import fetcher from '@zall/sdk/lib/fetcher';
import clsx from 'clsx';
import {noop} from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {useSWRConfig} from 'swr';

import {BORDER_PRIMARY} from '../constants/colors';
import useAsync from '../hooks/useAsync';
import useZallExtensionVersion from '../hooks/useZallExtensionVersion';
import DownloadExtensionAlert from './DownloadExtensionAlert';
import Header from './Header';

const RootContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: content-box;
  .main-container {
    flex: 1 1 70%;
    overflow-y: scroll;
  }
  .main-content {
    padding: 30px 139px 30px 151px;
    position: relative;
  }
  .main-content-mobile {
    height: 100%;
    margin: 15px;
  }
  .side-panel {
    flex: 1 1 30%;
    padding: 7px 30px 24px 24px;
    border-left: 0.5px solid ${BORDER_PRIMARY};
    overflow-y: scroll;
  }
  .hidden {
    display: none;
  }
`;

export const SidePanelContext =
  React.createContext<React.Dispatch<React.SetStateAction<React.ReactNode>>>(
    noop,
  );

const Layout: React.FC = ({children}) => {
  const {cache} = useSWRConfig();
  const [sidePanel, setSidePanel] = React.useState<React.ReactNode>(null);

  const isNative = process.env.REACT_APP_MODE === 'native';
  const accountsRequest = React.useMemo(() => fetcher('/accounts'), []);
  const {data, error, isLoading} = useAsync(accountsRequest);

  const extensionVersion = useZallExtensionVersion();

  React.useEffect(() => {
    if (error?.message === 'Unauthorized') {
      cache.delete('/accounts');
      window.location.assign(
        isNative ? '#/login' : `${process.env.REACT_APP_WEB_URL ?? ''}/signin`,
      );
    }
  }, [cache, error?.message, isNative]);

  if (!data && isLoading) {
    return null;
  }

  if (error?.message === 'Unauthorized') {
    return null;
  }

  return (
    <SidePanelContext.Provider value={setSidePanel}>
      <RootContainer>
        <div className="main-container">
          <Header />
          {extensionVersion === null && <DownloadExtensionAlert />}
          <div className="main-content">{children}</div>
        </div>
        <div className={clsx('side-panel', !sidePanel && 'hidden')}>
          {sidePanel}
        </div>
      </RootContainer>
    </SidePanelContext.Provider>
  );
};

export default Layout;
