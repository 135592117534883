import React from 'react';
import styled from 'styled-components';

import {GRAY_TEXT, PRIMARY, SECONDARY} from '../../constants/colors';

const ToggleWrapper = styled.span`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  .toggle {
    cursor: pointer;
    display: inline-block;
  }
  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 29px;
    height: 12px;
    position: relative;
    vertical-align: middle;
    &:before,
    &:after {
      content: '';
    }
    &:before {
      display: block;
      background: white;
      border-radius: 50%;
      box-shadow: 0 0 0 1px ${PRIMARY};
      width: 18px;
      height: 18px;
      position: absolute;
      top: -2px;
      left: 0;
      transition: left 0.25s;
    }
    .toggle:hover &:before {
      background: linear-gradient(to bottom, #fff 0%, #fff 100%);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  .toggle-checkbox:checked + .toggle-switch {
    background: ${SECONDARY};
    &:before {
      left: 12px;
      box-shadow: 0 0 0 1px #ff3eb2;
    }
  }

  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
`;

const ToggleSwitch = ({
  className,
  checked,
  onChange,
  label,
  withCaptions,
  ...props
}: {
  className?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  withCaptions?: boolean;
  props?: React.AnchorHTMLAttributes<HTMLInputElement>;
}): JSX.Element => (
  <ToggleWrapper>
    <label className="toggle">
      {withCaptions && (
        <span style={{color: checked ? SECONDARY : GRAY_TEXT}}>
          {checked ? 'On' : 'Off'}
        </span>
      )}
      <input
        className="toggle-checkbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <div className="toggle-switch" />
      <span className="toggle-label">{label}</span>
    </label>
  </ToggleWrapper>
);

export default ToggleSwitch;
