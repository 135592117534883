"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailProvider = void 0;
var EmailProvider;
(function (EmailProvider) {
    EmailProvider["Unknown"] = "Unknown";
    EmailProvider["Gmail"] = "GMAIL";
    EmailProvider["Office365"] = "OFFICE365";
    EmailProvider["Outlook"] = "OUTLOOK";
})(EmailProvider = exports.EmailProvider || (exports.EmailProvider = {}));
