import localforage from 'localforage';

export function createCache(): Promise<Map<string, unknown>> {
  return new Promise<Map<string, unknown>>(resolve => {
    const createdCache = new Map<string, unknown>();
    const store = localforage.createInstance({name: 'cache'});

    store
      .iterate((value, key) => {
        createdCache.set(key, value);
      })
      .then(() => {
        resolve(createdCache);
      });

    const saveCache = () => {
      for (const [key, value] of Array.from(createdCache.entries())) {
        store.setItem(key, value);
      }
    };

    window.addEventListener('beforeunload', saveCache);
  });
}
