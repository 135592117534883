import {accountsOutlook} from '@zall/sdk/mutate';
import {useFormik} from 'formik';
import React from 'react';
import styled from 'styled-components';

import Button from './common/Button';
import InputComponent from './common/Input';

const OutlookLoginPage: React.FC = () => {
  const {values, handleSubmit, handleChange, setErrors, errors, isSubmitting} =
    useFormik({
      initialValues: {
        username: '',
        password: '',
      },
      onSubmit: values => {
        return accountsOutlook(values)
          .then(account => {
            window.close();
          })
          .catch(() => {
            const invalidPasswordError = 'Invalid username or password';
            setErrors({
              username: invalidPasswordError,
              password: invalidPasswordError,
            });
          });
      },
    });

  return (
    <Container>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Input
          name="username"
          value={values.username}
          onChange={handleChange}
          hasError={!!errors.username}
        />
        <Input
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          hasError={!!errors.password}
        />

        {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}

        <Actions>
          <Button disabled={isSubmitting}>
            {isSubmitting ? 'Trying to login...' : 'Login'}
          </Button>
        </Actions>
      </Form>
    </Container>
  );
};

export default OutlookLoginPage;

const Container = styled.div`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled(InputComponent)`
  padding: 10px 12px 13px;
  margin: 0.5rem 0;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Actions = styled.div`
  margin: 0.5rem 0;
`;
