import {extensionLink} from '@zall/consts';
import React from 'react';
import styled from 'styled-components';

import {ReactComponent as ChevronIcon} from '../icons/chevron.svg';

const DownloadExtensionAlert: React.FC = () => {
  return (
    <Alert href={extensionLink} rel="noopener noreferrer" target="_blank">
      <span>
        Download <em>Zall chrome extension</em> from Google store
        <ChevronIcon />
      </span>
    </Alert>
  );
};

export default DownloadExtensionAlert;

const Alert = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: none;
  background: #ffe6f5;
  text-align: center;

  padding: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;

  em {
    font: inherit;
    color: #ff3eb2;
  }

  svg {
    margin-left: 13px;
    margin-bottom: -1px;
  }
`;
