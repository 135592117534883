"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLANS = void 0;
exports.PLANS = [
    {
        id: 'peasant',
        name: 'Zall Peasant',
        features: [
            'Suggest times from your calendar',
            'Suggest times from your email',
            'Free 1-mo with Royalty features',
        ],
    },
    {
        id: 'royal',
        name: 'Zall Royalty',
        features: [
            'All of Zall Peasant and includes',
            'Zall.co branding removed',
            'Cmd/Alt + J feature shortcut',
        ],
        price: {
            monthly: 4.99,
            annually: 48,
        },
    },
];
