/* eslint-disable */
import fetcher from '@zall/sdk/lib/fetcher';
export const heapTrack = (event: string) => {
  return fetcher(`/track`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event,
      data: {},
    }),
  });
};
