import 'react-confirm-alert/src/react-confirm-alert.css';

import {confirmAlert} from 'react-confirm-alert';
import styled from 'styled-components';

import PinkButton from './PinkButton';

type ButtonProp = {
  label: string;
  onClick: () => void;
};

interface ConformationModalProps {
  message: React.ReactNode;
  buttons: ButtonProp[];
}

export const conformationModal = ({
  message,
  buttons,
}: ConformationModalProps): void =>
  confirmAlert({
    customUI: ({onClose}) => {
      return (
        <ConformationModal>
          <div className="content">{message}</div>
          <div className="buttons-block">
            <PinkButton
              lg
              key={buttons[0].label}
              onClick={() => {
                buttons[0]?.onClick();
                onClose();
              }}
            >
              {buttons[0].label}
            </PinkButton>
            <PinkButton
              lg
              bordered
              key={buttons[1].label}
              onClick={() => {
                buttons[1]?.onClick();
                onClose();
              }}
            >
              {buttons[1].label}
            </PinkButton>
          </div>
        </ConformationModal>
      );
    },
  });

const ConformationModal = styled.div`
  max-width: 300px;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2.75099px;
  .content {
    text-align: center;
    font-size: 14px;
  }
  .buttons-block {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    button {
      margin: 0 5px;
    }
  }
`;
