import {UserSubscription} from '@zall/consts';
import fetcher from '@zall/sdk/lib/fetcher';
import useSWR from 'swr';

function useSubscription(): UserSubscription | undefined {
  const {data} = useSWR<UserSubscription>(`/subscription`, fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 15000,
  });
  return data;
}

export default useSubscription;
