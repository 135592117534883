import styled from 'styled-components';

const Input = styled.input<{hasError?: boolean}>`
  border: 0.5px solid #cad0d9;
  box-sizing: border-box;
  border-radius: 2px;
  height: 47px;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  padding-left: 10px;

  ::placeholder {
    color: #a4a8b7;
  }

  &:focus {
    outline: none;
    background-color: #fbfbfd;
  }

  ${({hasError}) =>
    hasError &&
    `
      border: 1px solid red;
  `}
`;

export default Input;
