import styled from 'styled-components';

import {PRIMARY, SECONDARY, WHITE} from '../../constants/colors';

interface LogoProps {
  dark?: boolean;
}

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  &:before {
    content: '\\2022';
    color: ${SECONDARY};
    margin-right: 5px;
  }
  &:after {
    color: ${(props: LogoProps) => (props.dark ? PRIMARY : WHITE)};
    content: 'Zall';
  }
`;

export default Logo;
