import styled from 'styled-components';

import PinkBadge from '../components/common/PinkBadge';
import {PRIMARY, SECONDARY} from '../constants/colors';

const AccountStyles = styled.div<{narrow?: boolean}>`
  ${({narrow}) =>
    narrow &&
    `
      margin: 0 auto;
      max-width: 684px;
  `}

  font-size: 14px;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;

  .data-column {
    flex: 1 1 75%;
  }

  .extra-column {
    flex: 1 1 25%;
    max-width: 338px;
    margin-left: 32px;
  }

  .section {
    border: 0.5px solid #a4a8b7;
    border-radius: 8px;
    padding: 16px 20px 0;
  }
  .section + .section {
    margin-top: 32px;
  }
  .title {
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 4px;
    color: #1c2329;
    text-transform: uppercase;
    svg {
      margin-right: 12px;
    }
  }
  .info-block {
    display: flex;
    padding: 16px 0;
    border-bottom: 0.5px solid rgba(164, 168, 183, 0.5);

    &:last-child {
      border-bottom: none;
    }
  }
  .info-block-name {
    width: 33%;
    font-weight: 500;
    color: rgba(8, 16, 35, 0.5);
  }
  .info-block-value {
    width: 66%;
    position: relative;
  }
  .info-block-link {
    width: 40%;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
    a {
      color: ${PRIMARY};
    }
  }
  .message {
    color: ${SECONDARY};
  }
  .payment-info {
    margin-left: 18px;
    margin-top: 10px;
    margin-right: 59px;
  }

  ${PinkBadge} {
    position: absolute;
    bottom: -3px;
  }

  .alert-sign {
    ::after {
      content: '!';
      font-weight: bold;
      width: 16px;
      height: 16px;
      background-color: rgba(255, 62, 178, 0.2);
      border-radius: 50%;
      color: ${SECONDARY};
      padding: 1px 7px;
      margin-right: 6px;
    }
  }

  .payment-failed {
    span {
      bottom: -3px;
      cursor: pointer;
    }
  }
  [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  /* Hide the tooltip content by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }

  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: -75px;
    margin-left: 130px;
    padding: 7px;
    width: 220px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #7f7f82;
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    content: attr(data-tooltip);
    text-align: left;
    font-size: 12px;
    line-height: 1.2;
  }
  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  thead tr,
  tbody tr {
    border-bottom: 1px solid rgba(164, 168, 183, 0.5);
  }

  th {
    color: rgba(8, 16, 35, 0.5);
    font-weight: 500;
  }

  th,
  td {
    padding: 14px 0 16px;
  }

  thead {
    font-size: 14px;
    line-height: 160%;
  }

  tbody {
    font-size: 14px;
    line-height: 180%;
  }

  tbody tr:last-child {
    border-bottom: none;
  }
`;

export default AccountStyles;
