import {debounce, isEqual} from 'lodash';
import React from 'react';

import useMemoCompare from './useMemoCompare';

export default function useMutationObserver(
  element: HTMLElement | null,
  options: MutationObserverInit,
  callback: MutationCallback,
): void {
  const callbackRef = React.useRef(callback);

  callbackRef.current = callback;

  const optionsFinal = useMemoCompare(options, isEqual);

  const update = React.useMemo(
    () =>
      debounce((mutations: MutationRecord[], observer: MutationObserver) => {
        callbackRef.current(mutations, observer);
      }, 100),
    [],
  );

  React.useEffect(() => {
    if (!element) {
      return;
    }

    const observer = new MutationObserver(
      (mutations: MutationRecord[], observer: MutationObserver) => {
        update(mutations, observer);
      },
    );

    observer.observe(element, optionsFinal);

    return () => {
      observer.disconnect();
    };
  }, [element, optionsFinal, update]);
}
