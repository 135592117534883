import styled from 'styled-components';

import {PRIMARY, WHITE} from '../../constants/colors';

const Button = styled.button<{white?: boolean; isLoading?: boolean}>`
  background-color: ${props => (props.white ? WHITE : PRIMARY)};
  color: ${props =>
    props.white ? PRIMARY : props.isLoading ? PRIMARY : WHITE};
  border: none;
  height: 51px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding: 0 25px;
  width: 100%;
  position: relative;
  cursor: pointer;
  &:disabled {
    filter: brightness(50%);
  }
  &:after {
    display: ${props => (props.isLoading ? 'block' : 'none')};
    content: '';
    position: absolute;
    border-radius: 100%;
    left: calc(50% - 10px);
    top: 23px;
    width: 0px;
    height: 0px;
    margin-top: -2px;
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-left-color: #fff;
    border-top-color: #fff;
    animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
  }
  @keyframes spin {
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes grow {
    to {
      width: 14px;
      height: 14px;
      margin-top: -8px;
      right: 13px;
    }
  }
`;

export default Button;
