import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {BORDER_PRIMARY, PRIMARY} from '../constants/colors';

const RootContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  background-color: white;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  margin-left: 32px;
  margin-bottom: 3px;

  .link {
    text-decoration: none;
    color: ${PRIMARY};
  }
`;

const NavTab = styled.div<{active: boolean}>`
  text-align: center;
  margin-right: 50px;
  font-size: 16px;
  line-height: 160%;
  padding-bottom: 6px;
  cursor: pointer;
  color: ${props => (props.active ? PRIMARY : BORDER_PRIMARY)};
  box-shadow: 0px 2px 0px 0px
    ${props => (props.active ? PRIMARY : 'transparent')};
`;

const AccountNavigation: React.FC = () => {
  const location = useLocation();
  return (
    <RootContainer>
      <Link to="/profile" className="link">
        <NavTab active={location.pathname !== '/payment'}>
          Account Information
        </NavTab>
      </Link>
      <Link to="/payment" className="link">
        <NavTab active={location.pathname === '/payment'}>
          Subscription Plan
        </NavTab>
      </Link>
    </RootContainer>
  );
};

export default AccountNavigation;
