import React from 'react';

import useElementState from './useElementState';

export interface ExtensionOptions {
  calendarUrl: string;
  shortcuts: Record<string, string | undefined>;
}

export default function useZallExtensionOptions():
  | ExtensionOptions
  | undefined {
  const optionsString = useElementState(
    document.body,
    undefined,
    {attributes: true},
    body => body.dataset.zallOptions ?? null,
  );

  return React.useMemo(() => {
    try {
      if (optionsString) {
        return JSON.parse(optionsString);
      }
    } catch {
      return undefined;
    }
  }, [optionsString]);
}
