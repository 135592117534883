import React from 'react';

interface AsyncResult<T> {
  data?: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  isLoading: boolean;
}

export default function useAsync<Data>(
  promise: Promise<Data>,
): AsyncResult<Data> {
  const [state, setState] = React.useState<AsyncResult<Data>>({
    isLoading: true,
  });

  React.useEffect(() => {
    promise
      .then(data => {
        setState({
          isLoading: false,
          data,
        });
      })
      .catch((error: unknown) => {
        setState({
          isLoading: false,
          error,
        });
      });
  }, [promise]);

  return state;
}
