import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PLANS} from '@zall/consts';
import fetcher from '@zall/sdk/lib/fetcher';
import {get, noop} from 'lodash';
import {DateTime} from 'luxon';
import React from 'react';
import ReactGA from 'react-ga';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';

import {BORDER_PRIMARY, GRAY_TEXT, SECONDARY} from '../constants/colors';
import useSubscription from '../hooks/useSubscription';
import {ReactComponent as LooseZallIcon} from '../icons/loose_zall.svg';
import peasantImg from '../icons/peasant.svg';
import royalImg from '../icons/royal.svg';
import AccountStyles from '../styles/AccountStyles';
import {unixTimestampToDateString} from '../utils/calendarUtils';
import {heapTrack} from '../utils/heapAnalytics';
import Button from './common/Button';
import {conformationModal} from './common/ConfirmationModal';
import Input from './common/Input';
import PinkBadge from './common/PinkBadge';
import Link from './Link';
import Plan from './profile/Plan';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '',
);

type Price = {
  id: string;
  price: number;
};

type Prices = {
  month: Price;
  year: Price;
};

type Subscription = {
  intentType: string;
  id: string;
  clientSecret: string;
  status: string;
};

type Intent = {
  status: string;
  // eslint-disable-next-line camelcase
  payment_method: string;
};

const PLAN_IMAGES: Record<string, string> = {
  royal: royalImg,
  peasant: peasantImg,
};

const PaymentPage: React.FC = () => {
  const [subscriptionData, setSubscriptionData] =
    React.useState<Subscription | null>(null);
  return (
    <Elements stripe={stripePromise} options={{locale: 'en'}}>
      {!subscriptionData ? (
        <Subscriptions setSubscriptionData={setSubscriptionData} />
      ) : (
        <Subscribe subscriptionData={subscriptionData} />
      )}
    </Elements>
  );
};

const Subscriptions: React.FC<{
  setSubscriptionData: (subscription: Subscription) => void;
}> = ({setSubscriptionData}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const currentTime = Math.round(+new Date() / 1000);

  const {data: prices} = useSWR<Prices>(`/subscription/prices`, fetcher);
  const currentSubscription = useSubscription();
  const isNoTrialAndCancelled = React.useMemo(
    () =>
      !currentSubscription?.trialEndDays &&
      currentSubscription?.status === 'canceled',
    [currentSubscription],
  );
  const isActiveNotCanceled = React.useMemo(
    () =>
      currentSubscription?.active &&
      !!currentSubscription?.card &&
      currentSubscription?.status &&
      currentSubscription?.status !== 'canceled',
    [currentSubscription],
  );

  const manageSubscription = React.useCallback(
    async (isAnnually: boolean, isUpdate?: boolean) => {
      const price = isAnnually ? prices?.year.id : prices?.month.id;
      setIsLoading(true);
      ReactGA.event({
        category: 'Subscription',
        action: `Clicked ${isUpdate ? 'update' : 'start'} subscription`,
      });
      heapTrack(`Clicked ${isUpdate ? 'update' : 'start'} subscription`);
      const subscriptionResponse = await fetcher('/subscription', {
        method: isUpdate ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          price,
        }),
      });

      setIsLoading(false);
      setSubscriptionData(subscriptionResponse as Subscription);
    },
    [setSubscriptionData, prices],
  );

  const cancelSubscription = React.useCallback(async () => {
    ReactGA.event({
      category: 'Subscription',
      action: 'Clicked cancel subscription',
    });
    heapTrack(`Clicked cancel subscription`);
    return conformationModal({
      message: (
        <>
          <LooseZallIcon /> <br />
          <br />
          <div>
            <div style={{fontSize: '24px', fontWeight: '700'}}>
              Are you sure?
            </div>
            <br />
            You want to cancel your subscription?
            <br /> You will lose access to zall at the end <br /> of this
            billing period
          </div>
        </>
      ),
      buttons: [
        {
          label: 'Go Back',
          onClick: noop,
        },
        {
          label: 'Lose Zall',
          onClick: () => {
            ReactGA.event({
              category: 'Subscription',
              action: 'Confirmed cancel subscription',
            });
            heapTrack('Confirmed cancel subscription');
            return fetcher('/subscription', {
              method: 'DELETE',
            }).then(() => history.push('/profile'));
          },
        },
      ],
    });
  }, [history]);

  const activePlanId = isActiveNotCanceled ? 'royal' : 'peasant';

  return (
    <RootContainer>
      <AccountStyles narrow>
        {currentSubscription?.freeRoyaltyEnd &&
          currentSubscription.freeRoyaltyEnd > Date.now() / 1000 && (
            <Alert>
              Extend Free Royalty before it expires on{' '}
              {DateTime.fromSeconds(
                currentSubscription.freeRoyaltyEnd,
              ).toFormat('MMM d, yyyy')}
            </Alert>
          )}
        <div className="data-column">
          <Plans>
            {PLANS.map(plan => (
              <Plan
                key={plan.id}
                plan={plan}
                image={PLAN_IMAGES[plan.id] ?? peasantImg}
                active={plan.id === activePlanId}
                loading={isLoading}
                onSwitch={
                  plan.id === 'peasant'
                    ? cancelSubscription
                    : manageSubscription
                }
              />
            ))}
          </Plans>
          <div className="section">
            <div className="title">Payment plan</div>
            <div className="info-block current-plan-block">
              <div className="info-block-name">Your current plan</div>
              <div className="info-block-value">
                {currentSubscription?.active &&
                (currentSubscription?.status !== 'canceled' ||
                  currentSubscription?.trialEndDays > 0)
                  ? !!currentSubscription?.card
                    ? `Royalty Plan, billed ${
                        currentSubscription.interval === 'month'
                          ? 'monthly'
                          : 'annually'
                      } `
                    : 'Royalty Plan'
                  : 'Peasant Plan'}
                {!!currentSubscription?.trialEndDays &&
                  currentSubscription?.active && (
                    <PinkBadge>
                      {currentSubscription?.trialEndDays} Day
                      {currentSubscription?.trialEndDays > 1 ? 's' : ''} Left In
                      Trial
                    </PinkBadge>
                  )}
                {!currentSubscription?.trialEndDays &&
                  !!currentSubscription?.freeRoyaltyEnd &&
                  currentSubscription.freeRoyaltyEnd > Date.now() / 1000 &&
                  currentSubscription?.active && (
                    <PinkBadge>
                      Free Royalty Expires{' '}
                      {DateTime.fromSeconds(
                        currentSubscription.freeRoyaltyEnd,
                      ).toFormat('MMM d, yyyy')}
                    </PinkBadge>
                  )}
                {isNoTrialAndCancelled &&
                  !!currentSubscription?.end &&
                  currentTime < currentSubscription?.end && (
                    <PinkBadge>
                      Active till&nbsp;
                      {unixTimestampToDateString(currentSubscription?.end)}
                    </PinkBadge>
                  )}
              </div>
              <div className="info-block-link">
                <Link href="/payment">
                  {currentSubscription?.status !== 'canceled'
                    ? 'Manage your plan'
                    : 'Get Zall Again'}
                </Link>
              </div>
            </div>
            {!isNoTrialAndCancelled && (
              <>
                <div className="info-block billing-block">
                  <div className="info-block-name">Billing</div>
                  <div className="info-block-value">
                    {currentSubscription?.active &&
                    !!currentSubscription?.price &&
                    currentSubscription?.status !== 'canceled' &&
                    !!currentSubscription?.card
                      ? `$${(currentSubscription?.price ?? 0) / 100} / ${
                          currentSubscription?.interval
                        }`
                      : '-'}
                  </div>
                  <div className="info-block-link">
                    <Link href="/payment">
                      {!!currentSubscription?.end ?? 'Cancel Subscription'}
                    </Link>
                  </div>
                </div>
                <div className="info-block invoice-block">
                  <div className="info-block-name">Next Invoice</div>
                  <div className="info-block-value">
                    {currentSubscription?.end &&
                    currentSubscription?.status !== 'canceled' &&
                    !!currentSubscription?.card
                      ? unixTimestampToDateString(currentSubscription?.end)
                      : '-'}
                  </div>
                  <div className="info-block-link">
                    {!!currentSubscription?.end ?? 'View Invoices'}
                  </div>
                </div>
                <div className="info-block payment-block">
                  <div className="info-block-name">Payment method</div>
                  <div className="info-block-value">
                    {currentSubscription?.card &&
                    currentSubscription?.status !== 'canceled'
                      ? currentSubscription?.card
                      : 'None'}
                    {currentSubscription?.status === 'past_due' &&
                      !currentSubscription?.active && (
                        <span
                          className="payment-failed"
                          data-tooltip="Your payment didn’t go through. Please update your payment method."
                        >
                          <PinkBadge>
                            <span className="alert-sign" />
                            Payment Failed
                          </PinkBadge>
                        </span>
                      )}
                  </div>
                  <div className="info-block-link">
                    {!!currentSubscription?.end ?? 'View Details'}
                  </div>
                </div>
              </>
            )}
            {currentSubscription?.status === 'trialing' &&
              !currentSubscription?.active && (
                <div className="payment-info">
                  <PinkBadge>
                    Your payment is being processed, it may take a few minutes.
                    If this does not happen, try to pay again.
                  </PinkBadge>
                </div>
              )}
          </div>
        </div>
      </AccountStyles>
    </RootContainer>
  );
};

const Subscribe: React.FC<{subscriptionData?: Subscription | null}> = ({
  subscriptionData,
}) => {
  const history = useHistory();
  const [intent, setIntent] = React.useState<Intent | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const [name, setName] = React.useState<string>('');

  const currentSubscription = useSubscription();

  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    if (intent?.status === 'succeeded') {
      fetcher('/subscription', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({paymentMethod: intent.payment_method}),
      }).then(() => {
        ReactGA.event({
          category: 'Subscription',
          action: 'Payment method recorded',
        });
        heapTrack('Payment method recorded');
        setIsLoading(false);
        history.push('/profile');
      });
    }
  }, [intent, history]);

  if (!stripe || !elements) {
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    ReactGA.event({
      category: 'Subscription',
      action: 'Submitted payment form',
    });
    heapTrack('Submitted payment form');
    const cardElement = elements.getElement(CardElement);

    if (cardElement && subscriptionData) {
      setError('');
      if (!(name && name.length > 1)) {
        ReactGA.event({
          category: 'Subscription',
          action: 'Payment validation error',
        });
        heapTrack('Payment validation error');
        setError('Name is required');
        setIsLoading(false);
        return;
      }
      const {clientSecret, intentType} = subscriptionData;
      const paymentMethod =
        intentType === 'payment'
          ? stripe.confirmCardPayment
          : stripe.confirmCardSetup;
      const stripeResponse = await paymentMethod(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
          },
        },
      });

      const event = new CustomEvent('zall-update-subscription', {
        bubbles: true,
        cancelable: true,
        composed: false,
      });

      window.dispatchEvent(event);

      if (stripeResponse.error) {
        ReactGA.event({
          category: 'Subscription',
          action: 'Payment failed',
        });
        heapTrack('Payment failed');
        setError(stripeResponse.error.message ?? '');
        setIsLoading(false);
        return;
      }

      ReactGA.event({
        category: 'Subscription',
        action: 'Payment method set',
      });
      heapTrack('Payment method set');

      setIntent(
        intentType === 'payment'
          ? get(stripeResponse, 'paymentIntent')
          : get(stripeResponse, 'setupIntent'),
      );
    }
  };

  return (
    <RootContainer>
      <div className="subscription-block">
        <div className="subscription-status">YOUR PLAN</div>
        <div className="subscription-title-block">Zall Royalty Plan</div>
        <div>1 account</div>
        <hr />

        <div className="subscription-title-block">
          <span>
            Starting Today
            {subscriptionData?.intentType !== 'payment' && (
              <PinkBadge>
                Free Trial for {currentSubscription?.trialEndDays} Day
                {currentSubscription?.trialEndDays &&
                currentSubscription?.trialEndDays > 1
                  ? 's'
                  : ''}
              </PinkBadge>
            )}
          </span>
          <span>{` $${(currentSubscription?.price ?? 0) / 100} / ${
            currentSubscription?.interval
          }`}</span>
        </div>
        <div className="subscription-title-block">
          <span>Next Billing Date</span>
          <span>{unixTimestampToDateString(currentSubscription?.end)}</span>
        </div>
        <div>
          After today, you will next be billed on&nbsp;
          {unixTimestampToDateString(currentSubscription?.end)}. Cancel anytime.
        </div>
      </div>
      <div className="subscription-block-gray">
        <form onSubmit={handleSubmit}>
          <CardElement
            options={{
              classes: {
                base: 'card-element',
              },
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#a4a8b7',
                  },
                },
              },
            }}
          />
          <br />

          <Input
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <br />
          <Button isLoading={isLoading} disabled={isLoading}>
            Subscribe
          </Button>
          <div className="error-message">{error}</div>
        </form>
      </div>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  .subscription-block {
    position: relative;
    padding: 20px 30px;
  }

  .current-plan-block .info-block-value > span {
    position: relative;
    display: block;
    width: fit-content;
    margin-left: 0;
    margin-top: 5px;
  }
  .subscription-block::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    padding: 1px;
    background: linear-gradient(to left, #0057ff, #db00ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .subscription-block-gray {
    margin-top: 20px;
    position: relative;
    padding: 20px 30px;
    border-radius: 4px;
    border: 1px solid ${BORDER_PRIMARY};
    button {
      width: fit-content;
      margin-top: 20px;
    }
  }
  .subscription-status {
    color: ${SECONDARY};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
  }

  .subscription-title-block {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subscription-trial {
    color: ${SECONDARY};
  }

  .price {
    font-weight: bold;
    font-size: 48px;
    line-height: 147%;
  }
  .period {
    color: ${GRAY_TEXT};
    font-weight: bold;
    font-size: 20px;
    line-height: 147%;
  }

  .info {
    color: ${GRAY_TEXT};
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
  }

  .buttons-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    button {
      z-index: 1;
      width: fit-content;
    }
  }
  .buttons-block-active {
    justify-content: end;
  }
  .billing-switcher {
    margin-bottom: 20px;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: ${GRAY_TEXT};
    span:first-child {
      margin-right: 13px;
    }
    span:last-child {
      margin-left: 5px;
    }
  }

  .billing-switcher-active {
    color: ${SECONDARY};
  }

  .save-annually {
    color: ${SECONDARY};
    font-weight: bold;
    font-size: 20px;
    line-height: 147%;
    margin-left: 7px;
  }

  .card-element {
    border: 0.5px solid #cad0d9;
    box-sizing: border-box;
    border-radius: 2px;
    height: 47px;
    padding-top: 12px;
    padding-left: 12px;
    ::placeholder {
      font-family: 'Work Sans', sans-serif;
      font-weight: normal;
      font-smooth: antialiased;
    }
  }

  .error-message {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: red;
  }
`;

export default PaymentPage;

const Plans = styled.div`
  display: flex;
  margin-bottom: 32px;

  & > * + * {
    margin-left: 32px;
  }
`;

const Alert = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: #ff3eb2;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  padding: 12px;
`;
