import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {SWRConfig} from 'swr';

import App from './App';
import {createCache} from './cache';
import reportWebVitals from './reportWebVitals';

createCache().then(cache => {
  ReactDOM.render(
    <SWRConfig value={{provider: () => cache}}>
      <App />
    </SWRConfig>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
