import styled from 'styled-components';

import {SECONDARY, WHITE} from '../../constants/colors';

const PinkButton = styled.button<{lg?: boolean; bordered?: boolean}>`
  background-color: ${props => (props.bordered ? WHITE : '#ffebf7')};
  border-color: ${props => (props.bordered ? SECONDARY : '#ffebf7')};
  color: ${SECONDARY};
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  font-weight: bold;
  font-size: 16px;
  line-height: 180%;
  cursor: pointer;
  height: 36px;
  white-space: nowrap;
  width: ${props => (props.lg ? '130px' : 'auto')};
  span {
    font-weight: normal;
  }
`;

export default PinkButton;
