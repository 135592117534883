import fetcher from '@zall/sdk/lib/fetcher';
import React from 'react';
import styled from 'styled-components';

import {PRIMARY} from '../constants/colors';
import AccountNavigation from './AccountNavigation';
import Logo from './common/Logo';

const RootContainer = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 10px 139px 0 60px;
  border-bottom: 0.5px solid #a4a8b7;

  .link {
    text-decoration: none;
    color: ${PRIMARY};
  }
`;

const MenuButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 35px;
`;

const MenuContainer = styled.div`
  display: flex;
`;

const signOut = () =>
  fetcher('/logout', {method: 'POST'}).then(() => {
    const event = new CustomEvent('zall-update-subscription', {
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    window.dispatchEvent(event);

    window.location.reload();
  });

const Header: React.FC = () => {
  return (
    <RootContainer>
      <Logo dark />
      <AccountNavigation />
      <MenuContainer>
        <MenuButton onClick={signOut}>Logout</MenuButton>
      </MenuContainer>
    </RootContainer>
  );
};

export default Header;
