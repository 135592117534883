import styled from 'styled-components';

import {SECONDARY} from '../../constants/colors';

const PinkBadge = styled.span<{wrap?: boolean}>`
  background: rgba(255, 62, 178, 0.1);
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: ${SECONDARY};
  white-space: ${props => (props.wrap ? 'normal' : 'nowrap')};
  position: absolute;
  margin-left: 10px;
  padding: 4px 7px;
`;

export default PinkBadge;
