import {useGetUserInvited} from '@zall/sdk/swr';
import {DateTime} from 'luxon';
import React from 'react';
import semver from 'semver';
import styled from 'styled-components';

import useSubscription from '../hooks/useSubscription';
import useZallExtensionOptions from '../hooks/useZallExtensionOptions';
import useZallExtensionVersion from '../hooks/useZallExtensionVersion';
import ShareLinkImage from '../icons/share-link-img.svg';
import AccountStyles from '../styles/AccountStyles';
import ValueEditor from './profile/ValueEditor';
import Shortcut from './Shortcut';

const ProfilePage: React.FC = () => {
  const currentSubscription = useSubscription();
  const extVersion = useZallExtensionVersion();
  const extOptions = useZallExtensionOptions();
  const {data: invitedUsers} = useGetUserInvited();
  const shareLink = React.useMemo(
    () =>
      `${process.env.REACT_APP_WEB_URL ?? ''}?invite=${
        currentSubscription?.inviteCode
      }`,
    [currentSubscription],
  );

  const selectInput = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    },
    [],
  );

  const handleCopy = React.useCallback(() => {
    navigator.clipboard.writeText(shareLink);
  }, [shareLink]);

  const validateCalendarUrl = React.useCallback((newValue: string) => {
    try {
      new URL(newValue);
      return true;
    } catch {
      return false;
    }
  }, []);

  const normalizeCalendarUrl = React.useCallback((newValue: string) => {
    try {
      const url = new URL(newValue);

      if (
        url.hostname === 'outlook.office.com' &&
        url.pathname.startsWith('/calendar')
      ) {
        url.pathname = '/calendar';
      }

      if (
        url.hostname === 'calendar.google.com' &&
        url.pathname.startsWith('/calendar')
      ) {
        const parts = url.pathname.slice(1).split('/');

        if (parts[1] === 'u' && parts[3] === 'r') {
          url.pathname = `/${parts.slice(0, 4).join('/')}`;
        }
      }

      return url.toString();
    } catch {
      return null;
    }
  }, []);

  const handleCalendarUrlSave = React.useCallback((newValue: string) => {
    const event = new CustomEvent('zall-set-calendar-url', {
      detail: {
        value: newValue,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    window.dispatchEvent(event);
  }, []);

  React.useEffect(() => {
    const event = new CustomEvent('zall-update-subscription', {
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    window.dispatchEvent(event);
  }, []);

  return (
    <AccountStyles>
      <div className="data-column">
        <div className="section">
          <div className="title">Profile</div>
          {currentSubscription?.name && (
            <div className="info-block">
              <div className="info-block-name">Name</div>
              <div className="info-block-value">{currentSubscription.name}</div>
            </div>
          )}
          <div className="info-block">
            <div className="info-block-name">Email</div>
            <div className="info-block-value">{currentSubscription?.email}</div>
          </div>
          <div className="info-block">
            <div className="info-block-name">Timezone</div>
            <div className="info-block-value">
              {DateTime.fromJSDate(new Date()).toFormat('ZZZZ')}
            </div>
          </div>
          <div className="info-block">
            <div className="info-block-name">Country</div>
            <div className="info-block-value">United States</div>
          </div>
          <div className="info-block">
            <div className="info-block-name">Preferred language</div>
            <div className="info-block-value">English</div>
          </div>
        </div>

        {extVersion && semver.gte(extVersion, '1.5.0') && (
          <div className="section">
            <div className="title">Settings</div>
            <div className="info-block">
              <div className="info-block-name">Calendar hotkey</div>
              <div className="info-block-value">
                {extOptions?.shortcuts['open-calendar'] ? (
                  <Shortcut shortcut={extOptions.shortcuts['open-calendar']} />
                ) : (
                  'Unset'
                )}
              </div>
            </div>
            <div className="info-block">
              <div className="info-block-name">Email Account</div>
              <div className="info-block-value">
                <ValueEditor
                  key={extOptions?.calendarUrl ?? ''}
                  value={extOptions?.calendarUrl ?? ''}
                  onSave={handleCalendarUrlSave}
                  validate={validateCalendarUrl}
                  normalize={normalizeCalendarUrl}
                />
              </div>
            </div>
          </div>
        )}

        <div className="section">
          <div className="title">Sharing with friends</div>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Email</th>
                <th>Rewards</th>
              </tr>
            </thead>
            <tbody>
              {invitedUsers === undefined && (
                <tr>
                  <td colSpan={3}>Loading...</td>
                </tr>
              )}
              {invitedUsers?.length === 0 && (
                <tr>
                  <td colSpan={3}>No invited users yet</td>
                </tr>
              )}
              {invitedUsers?.map(({email, date}) => (
                <tr key={email}>
                  <td>{DateTime.fromISO(date).toFormat('M/d/yyyy')}</td>
                  <td>{email}</td>
                  <td>1 month Free Royalty Features</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ShareSection className="extra-column">
        <img src={ShareLinkImage} alt="Share Zall with friends" />
        <h4>Share Zall with Friends</h4>
        <p>
          Everytime someone signs up using your personalized link, you will
          receive 1 month of free Zall with all our royalty features!
        </p>
        <form>
          <input readOnly value={shareLink} onClick={selectInput} />
          <button type="button" onClick={handleCopy}>
            Copy
          </button>
        </form>
      </ShareSection>
    </AccountStyles>
  );
};

export default ProfilePage;

const ShareSection = styled.div`
  border: 0.5px solid #a4a8b7;
  border-radius: 8px;
  padding: 18px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 113%;
    text-align: center;
    color: #000000;
    margin: 32px 0 10px;
  }

  p {
    margin: 10px 0 22px;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: rgba(8, 16, 35, 0.75);
  }

  form {
    width: 100%;
    display: flex;
  }

  input {
    flex-grow: 1;
  }

  button {
    cursor: pointer;
    padding: 5.5px 13.5px;
    margin-left: 8px;
    font-size: 14px;
    border: 0.5px solid #ff3eb2;
    color: #ff3eb2;
    background: #fff;
    transition: background-color 0.25s ease;

    &:active {
      background: rgba(255, 62, 178, 0.1);
    }
  }

  input {
    padding: 7px 11.5px;
    font-size: 12px;
    color: #ff3eb2;
    border: none;
    background: rgba(255, 62, 178, 0.1);
  }

  button,
  input {
    border-radius: 2px;
    line-height: 180%;
  }
`;
